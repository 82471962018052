import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/700.css';
import './lib/i18n';

const { REACT_APP_ENVIRONMENT } = process.env;
const isLive = REACT_APP_ENVIRONMENT?.toLowerCase() === 'live';

// import { StrictMode, Suspense } from 'react';
// import { render } from 'react-dom';
// import { BrowserRouter } from 'react-router-dom';
//
// import { App } from './App';
// import reportWebVitals from './reportWebVitals';

window.location.href = isLive ? 'https://my.pureprofile.com' : 'https://dev-my.pureprofile.com';

// render(
//   <StrictMode>
//     <BrowserRouter>
//       <Suspense fallback={null}>
//         <App />
//       </Suspense>
//     </BrowserRouter>
//   </StrictMode>,
//   document.getElementById('root')
// );
//
// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
