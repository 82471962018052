import ky from 'ky';
import store from 'storejs';
import { getCredentials } from './utils';

const { REACT_APP_API_HOST, REACT_APP_ENVIRONMENT, REACT_APP_CREDENTIALS } = process.env;
const isLive = REACT_APP_ENVIRONMENT?.toLowerCase() === 'live';

export const api = ky.create({
  timeout: false,
  prefixUrl: REACT_APP_API_HOST ? `${REACT_APP_API_HOST}/v1/community` : 'v1/community',
  credentials: getCredentials(REACT_APP_CREDENTIALS),
  hooks: {
    beforeRequest: [
      (request) => {
        if (!isLive) {
          const token = store.get('access-token');
          if (token) {
            request.headers.set('Authorization', `Bearer ${token}`);
          }
        }
      },
    ],
  },
});
