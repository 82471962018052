import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { api } from './api';

interface Bundle {
  name: string;
  translations: { [key: string]: string };
}

interface TranslationsResponse {
  bundle: Bundle;
  message: string;
}

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      request: async (_options, url, _payload, callback) => {
        try {
          const [, lng, ns] = url.substring(1, url.indexOf('.json')).split('/');

          const { bundle } = await api
            .get(`tools/translations?bundle=${ns}&locale=${lng.toUpperCase()}`)
            .json<TranslationsResponse>();

          callback(null, { data: JSON.stringify(bundle.translations), status: 200 });
        } catch {
          callback(null, { data: '', status: 200 });
        }
      },
    },
    fallbackLng: 'ENG-GB',
    load: 'currentOnly',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
