import { Reward } from '../data/useRewards';

export function getRewardName(locale: string | string[], reward: Reward) {
  const costCurrency = new Intl.NumberFormat(locale, { currency: reward.cost_currency, style: 'currency' }).format(
    reward.cost
  );

  if (reward.type === 'PayPal') {
    return `PayPal ${costCurrency}`;
  }

  if (typeof reward.meta_data === 'undefined' || typeof reward.meta_data.brand_name === 'undefined') {
    return reward.name;
  }

  return `${reward.meta_data.brand_name as string} gift card ${costCurrency}`;
}

export function getCredentials(credentials?: string): RequestCredentials {
  return (
    credentials && ['include', 'omit', 'same-origin'].includes(credentials) ? credentials : 'include'
  ) as RequestCredentials;
}
